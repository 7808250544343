import { LanguageIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  createBook,
  createVocabulary,
  updateBook,
} from '../business/api/book-service'
import { translate } from '../business/api/translator-service'
import {
  deleteVocabulary,
  updateVocabulary,
} from '../business/api/vocabulary-service'
import BookDetails from '../business/dto/book-details'
import VocabularyDetails from '../business/dto/vocabulary-details'
import VocabularyProperties from '../business/dto/vocabulary-properties'
import IosBottomContext from '../components/ios/IosBottomContext'
import IosNavbar from '../components/ios/IosNavbar'
import useData from '../contexts/data-auth'

export default function VocabularyEditNew() {
  const [changes, setChanges] = useState<VocabularyProperties>({
    key: '',
    value: '',
  })
  const navigate = useNavigate()
  const { bookId, vocabularyId } = useParams()
  const { data, setBooks } = useData()

  const getBook = (): BookDetails => {
    return data.books.find((post) => post.id === bookId)
  }

  const getVocabulary = (): VocabularyDetails => {
    return getBook().vocabularies.find(
      (vocabulary) => vocabulary.id === vocabularyId,
    )
  }

  useEffect(() => {
    if (bookId) {
      if (vocabularyId) {
        const vocabulary = getVocabulary()

        setChanges({
          key: vocabulary.key,
          value: vocabulary.value,
        })
      }
    }
  }, [])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setChanges({ ...changes, [e.target.name]: e.target.value })
  }

  const onActionClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (vocabularyId) {
      updateVocabulary(vocabularyId, changes).then(
        (response: VocabularyDetails) => {
          const book = getBook()
          const index = book.vocabularies.findIndex(
            (vocabulary) => vocabulary.id === vocabularyId,
          )
          book.vocabularies.splice(index, 1, response)
          setBooks(data.books)
          navigate(`/books/${bookId}`, { replace: true })
        },
      )
    } else {
      createVocabulary(bookId, changes).then((response: VocabularyDetails) => {
        const book = getBook()
        book.vocabularies.push(response)
        setBooks(data.books)
        navigate(`/books/${bookId}`, { replace: true })
      })
    }
  }

  const onTranslateKey = () => {
    translate(changes.key, 'de', 'en-US').then((response) =>
      setChanges({ ...changes, value: response.translation }),
    )
  }

  const onTranslateValue = () => {
    translate(changes.value, 'en', 'de').then((response) =>
      setChanges({ ...changes, key: response.translation }),
    )
  }

  const onDelete = () => {
    deleteVocabulary(vocabularyId).then(() => {
      const book = getBook()
      const index = book.vocabularies.findIndex(
        (vocabulary) => vocabulary.id === vocabularyId,
      )
      if (book) {
        book.vocabularies.splice(index, 1)
        setBooks(data.books)
      }
      navigate(`/books/${bookId}`, { replace: true })
    })
  }

  return (
    <form onSubmit={onActionClicked}>
      {bookId && changes && (
        <>
          <IosNavbar
            title={vocabularyId ? 'Edit Vocabulary' : 'New Vocabulary'}
            leftAction={
              <Link
                to={vocabularyId ? `/books/${bookId}` : `/books/${bookId}`}
                replace={true}
              >
                Cancel
              </Link>
            }
            rightAction={
              <button type="submit" className="font-normal">
                Done
              </button>
            }
          />

          <div className="bg-white dark:bg-gray-800 mb-6">
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 dark:text-gray-500">
                  Englisch:
                </span>
              </div>
              <input
                type="text"
                name="value"
                id="value"
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[115px] pr-6 py-3"
                value={changes.value}
                onChange={handleInputChange}
                autoFocus
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-6 flex items-center text-sky-500"
                onClick={onTranslateValue}
              >
                <LanguageIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="pl-6">
              <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 dark:text-gray-500">
                  Deutsch:
                </span>
              </div>
              <input
                type="text"
                name="key"
                id="key"
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[115px] pr-16 py-3"
                value={changes.key}
                onChange={handleInputChange}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-6 flex items-center text-sky-500"
                onClick={onTranslateKey}
              >
                <LanguageIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          </div>

          {vocabularyId && (
            <ul
              role="list"
              className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg mx-3 mb-6 overflow-hidden"
            >
              <li>
                <button
                  className="relative w-full py-2 px-3 flex items-center justify-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600"
                  onClick={onDelete}
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  <div className="text-red-600">Delete</div>
                </button>
              </li>
            </ul>
          )}

          <IosBottomContext
            description={`${data.deeplCharactersLeft} Characters left`}
          />
        </>
      )}
    </form>
  )
}
