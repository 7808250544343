import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid'
import moment, { DurationInputArg1, DurationInputArg2 } from 'moment'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { updateVocabulary } from '../business/api/vocabulary-service'
import VocabularyDetails from '../business/dto/vocabulary-details'
import VocabularyProperties from '../business/dto/vocabulary-properties'
import IosBottomContext from '../components/ios/IosBottomContext'
import IosNavbar from '../components/ios/IosNavbar'
import useData from '../contexts/data-auth'

const items: {
  title: string
  subtitle: string
  amount: DurationInputArg1
  unit: DurationInputArg2
}[] = [
  { title: 'Box 1', subtitle: 'Daily', amount: 0, unit: 'days' },
  { title: 'Box 2', subtitle: 'After 1 Day', amount: 1, unit: 'day' },
  { title: 'Box 3', subtitle: 'After 3 Days', amount: 3, unit: 'days' },
  { title: 'Box 4', subtitle: 'After 1 Week', amount: 1, unit: 'week' },
  { title: 'Box 5', subtitle: 'After 2 Weeks', amount: 2, unit: 'weeks' },
  { title: 'Box 6', subtitle: 'After 1 Month', amount: 1, unit: 'month' },
  { title: 'Box 7', subtitle: 'After 3 Months', amount: 3, unit: 'months' },
  { title: 'Box 8', subtitle: 'After 6 Months', amount: 6, unit: 'months' },
  { title: 'Box 9', subtitle: 'After 1 Year', amount: 1, unit: 'year' },
]

export default function Box() {
  const [vocabularies, setVocabularies] = useState<VocabularyDetails[]>(null)
  const [currentVocabulary, setCurrentVocabulary] = useState<VocabularyDetails>(
    null,
  )
  const [showSolution, setShowSolution] = useState<boolean>(false)
  const { data, setBooks } = useData()
  const { boxIndex } = useParams()

  useEffect(() => {
    showNext()
  }, [])

  const showNext = () => {
    const vocabularies = getVocabularies()
    setVocabularies(vocabularies)
    if (vocabularies.length) {
      setCurrentVocabulary(selectRandom(vocabularies))
    }
  }

  const getVocabularies = () => {
    return data.books
      .flatMap((book) => book.vocabularies)
      .filter((vocabulary) => vocabulary.box === +boxIndex)
      .filter((vocabulary) =>
        moment().isAfter(
          moment(vocabulary.lastQueriedOn)
            .startOf('day')
            .add(
              items[vocabulary.box].amount as DurationInputArg1,
              items[vocabulary.box].unit as DurationInputArg2,
            ),
        ),
      )
  }

  const selectRandom = (array: VocabularyDetails[]) => {
    const index = Math.floor(Math.random() * array.length)
    return array[index]
  }

  const onShowSolution = () => {
    setShowSolution(true)
  }

  const onWrong = () => {
    const properties: VocabularyProperties = {
      key: currentVocabulary.key,
      value: currentVocabulary.value,
      box: 0,
      right: currentVocabulary.right,
      wrong: currentVocabulary.wrong + 1,
      lastQueriedOn: new Date(),
    }
    updateVocabulary(currentVocabulary.id, properties).then(
      (response: VocabularyDetails) => {
        currentVocabulary.key = response.key
        currentVocabulary.value = response.value
        currentVocabulary.box = response.box
        currentVocabulary.right = response.right
        currentVocabulary.wrong = response.wrong
        currentVocabulary.lastQueriedOn = response.lastQueriedOn

        setBooks(data.books)
        setShowSolution(false)
        showNext()
      },
    )
  }

  const onRight = () => {
    const properties: VocabularyProperties = {
      key: currentVocabulary.key,
      value: currentVocabulary.value,
      box: currentVocabulary.box + 1,
      right: currentVocabulary.right + 1,
      wrong: currentVocabulary.wrong,
      lastQueriedOn: new Date(),
    }
    updateVocabulary(currentVocabulary.id, properties).then(
      (response: VocabularyDetails) => {
        currentVocabulary.key = response.key
        currentVocabulary.value = response.value
        currentVocabulary.box = response.box
        currentVocabulary.right = response.right
        currentVocabulary.wrong = response.wrong
        currentVocabulary.lastQueriedOn = response.lastQueriedOn

        setBooks(data.books)
        setShowSolution(false)
        showNext()
      },
    )
  }

  return (
    <>
      <IosNavbar
        title={+boxIndex < 8 ? `Box ${+boxIndex + 1}` : 'Gelernt'}
        leftAction={
          <Link
            to="/boxes"
            replace={true}
            className="flex items-center ml-[-24px]"
          >
            <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
            <span className="m-[-4px]">Back</span>
          </Link>
        }
      />

      {vocabularies && vocabularies.length > 0 && (
        <>
          {currentVocabulary && (
            <div className="mx-3 space-y-3 mb-6">
              <div className="bg-white border border-gray-900/[0.1] rounded-xl px-3 py-5">
                <div className="flex flex-col justify-center items-center">
                  <div className="text-gray-900 dark:text-gray-200 truncate">
                    {currentVocabulary.key}
                  </div>
                  <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    deutsch
                  </p>
                </div>
              </div>
              {showSolution && (
                <div className="bg-white border border-gray-900/[0.1] rounded-xl px-3 py-5">
                  <div className="flex flex-col justify-center items-center">
                    <div className="text-gray-900 dark:text-gray-200 truncate">
                      {currentVocabulary.value}
                    </div>
                    <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                      englisch
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="fixed bottom-[96px] left-0 right-0 z-40">
            <div className="max-w-4xl mx-auto">
              <div className="flex mx-6 py-3">
                {!showSolution && (
                  <button
                    onClick={onShowSolution}
                    className="relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <InformationCircleIcon
                        className="h-5 w-5 text-sky-400"
                        aria-hidden="true"
                      />
                    </span>
                    Lösung anzeigen
                  </button>
                )}
                {showSolution && (
                  <>
                    <button
                      onClick={onWrong}
                      className="relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <XCircleIcon
                          className="h-5 w-5 text-red-300"
                          aria-hidden="true"
                        />
                      </span>
                      Wrong
                    </button>
                    <button
                      onClick={onRight}
                      className="relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 ml-3"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </span>
                      Right
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {vocabularies && vocabularies.length === 0 && (
        <div className="mx-3 space-y-3 mb-6">
          <div className="bg-white border border-gray-900/[0.1] rounded-xl p-6">
            <img
              className="p-6 mb-6"
              src="/assets/img/undraw_superhero_kguv.svg"
            />
            <div className="text-gray-500 font-medium text-center">
              This box is empty. At least for today.
            </div>
          </div>
        </div>
      )}

      {vocabularies && (
        <IosBottomContext
          description={`${vocabularies.length} more ${
            vocabularies.length === 1 ? 'Vocabulary' : 'Vocabularies'
          }`}
        />
      )}
    </>
  )
}
