import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { BookOpenIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BookDetails from '../business/dto/book-details'
import IosBottomContext from '../components/ios/IosBottomContext'
import IosListGroup from '../components/ios/IosListGroup'
import IosListGroupItem from '../components/ios/IosListGroupItem'
import IosNavbar from '../components/ios/IosNavbar'
import IosSearch from '../components/ios/IosSearch'
import useData from '../contexts/data-auth'

export default function Books() {
  const [search, setSearch] = useState('')
  const [touched, setTouched] = useState(-1)
  const { data } = useData()

  useEffect(() => {
    /*
    setTimeout(async () => {
      let keyMaterial = await getKeyMaterial();
      
      var enc = new TextEncoder();
      let salt = enc.encode("fabian@steinert.io");
      let key = await getKey(keyMaterial, salt);
      console.log('key:', key, JSON.stringify(key));
    });
    */
  }, [])

  const filter = (books: BookDetails[]) => {
    return books.filter((book: BookDetails) =>
      book.title.toLowerCase().includes(search.toLowerCase()),
    )
  }

  return (
    <>
      <IosNavbar title="Books">
        {
          <IosSearch
            placeholder="Search book"
            search={search}
            setSearch={setSearch}
          />
        }
      </IosNavbar>

      <IosListGroup>
        {React.Children.toArray(
          filter(data.books).map((item) => (
            <IosListGroupItem
              key={item.id}
              title={item.title}
              subtitle={item.author}
              icon={BookOpenIcon}
              iconColor={'bg-sky-500'}
              extra={`${item.vocabularies.length}`}
              to={`/books/${item.id}`}
            />
          )),
        )}
      </IosListGroup>

      <IosBottomContext
        description={`${filter(data.books).length} ${
          filter(data.books).length === 1 ? 'Book' : 'Books'
        } / ${filter(data.books).flatMap((x) => x.vocabularies).length} ${
          filter(data.books).flatMap((x) => x.vocabularies).length === 1
            ? 'Vocabulary'
            : 'Vocabularies'
        }`}
        rightAction={
          <Link to="/new-book" replace={true}>
            <PencilSquareIcon className="h-6 w-6" />
          </Link>
        }
      />
    </>
  )
}
