import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const IosSearch = (props: {
  search: string;
  placeholder?: string;
  setSearch: (_: string) => void;
}) => {
  return (
    <>
      <div className="pb-3 px-6 flex items-center w-full">
        <div className="relative rounded-lg w-full">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full pl-10 py-1 bg-gray-200 dark:bg-gray-700 dark:text-gray-200 border-none focus:ring-0 sm:text-sm rounded-xl"
            placeholder={`${props.placeholder ? props.placeholder : "Search"}`}
            value={props.search}
            onChange={(e) => props.setSearch(e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default IosSearch;
