import { LockClosedIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IosNavbar from "../components/ios/IosNavbar";
import useAuth from "../contexts/use-auth";

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = (location.state as any)?.from?.pathname || "/";

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;
    let password = formData.get("password") as string;

    auth.signin({ username, password }, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  };

  return (
    <>
      <IosNavbar
        title=""
        titleSize="text-2xl"
        rightAction={
          <Link to="/register" replace={true}>
            Sign up
          </Link>
        }
      />
      <div className="mt-12 max-w-4xl mx-auto">
        <div className="px-6">
          <img src="/assets/img/icon.svg" className="w-16 h-16" />
        </div>
        <h1 className="transition text-3xl pt-2 pb-5 px-6 font-bold text-gray-900 dark:text-gray-200 sm:truncate">
          Sign in to your account
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="bg-white dark:bg-gray-800">
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 text-gray-500">Username:</span>
              </div>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[129px] pr-6 py-3"
              />
            </div>
            <div className="pl-6">
              <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 text-gray-500">Password:</span>
              </div>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[129px] pr-6 py-3"
              />
            </div>
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          </div>

          <div className="flex items-center justify-between px-6 pt-6 pb-3">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 bg-gray-800 text-sky-600 focus:ring-teal-500 border-gray-300 dark:border-gray-50/[0.06] rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900 dark:text-gray-200"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-sky-500 hover:text-sky-600 dark:text-sky-500 dark:hover:text-sky-400"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div className="px-6 py-3">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-sky-500 group-hover:text-sky-400"
                  aria-hidden="true"
                />
              </span>
              Sign in
            </button>
          </div>
          <div></div>
        </form>
      </div>
    </>
  );
}
