import { ChevronLeftIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import BookDetails from "../business/dto/book-details";
import VocabularyDetails from "../business/dto/vocabulary-details";
import IosBottomContext from "../components/ios/IosBottomContext";
import IosListGroup from "../components/ios/IosListGroup";
import IosListGroupItem from "../components/ios/IosListGroupItem";
import IosNavbar from "../components/ios/IosNavbar";
import IosSearch from "../components/ios/IosSearch";
import useData from "../contexts/data-auth";

export default function Book() {
  const [search, setSearch] = useState("");
  const [touched, setTouched] = useState(-1);
  const { data } = useData();
  const { bookId } = useParams();

  const filter = (vocabularies: VocabularyDetails[]) => {
    return vocabularies.filter(
      (vocabulary: VocabularyDetails) =>
        vocabulary.key.toLowerCase().includes(search.toLowerCase()) ||
        vocabulary.value.toLowerCase().includes(search.toLowerCase())
    );
  };

  const getBook = (): BookDetails => {
    return data.books.find((x) => x.id === bookId);
  };

  return (
    <>
      {getBook() && (
        <IosNavbar
          title={getBook().title}
          leftAction={
            <Link
              to="/books"
              replace={true}
              className="flex items-center ml-[-24px]"
            >
              <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
              <span className="m-[-4px]">Back</span>
            </Link>
          }
          rightAction={
            <Link to={`/books/${bookId}/edit`} replace={true}>
              Edit
            </Link>
          }
        >
          {
            <IosSearch
              placeholder="Search vocabulary"
              search={search}
              setSearch={setSearch}
            />
          }
        </IosNavbar>
      )}

      <IosListGroup>
        {React.Children.toArray(
          filter(getBook().vocabularies).map((item) => (
            <IosListGroupItem
              key={item.id}
              title={item.key}
              subtitle={item.value}
              iconColor={"bg-amber-500"}
              extra={`Box ${item.box + 1}`}
              to={`/books/${bookId}/vocabularies/${item.id}`}
            />
          ))
        )}
      </IosListGroup>

      <IosBottomContext
        description={`${filter(getBook().vocabularies).length} ${
          filter(getBook().vocabularies).length === 1
            ? "Vocabulary"
            : "Vocabularies"
        }`}
        rightAction={
          <Link to={`/books/${bookId}/new-vocabulary`} replace={true}>
            <PencilSquareIcon className="h-6 w-6" />
          </Link>
        }
      />
    </>
  );
}
