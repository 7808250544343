import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  BellIcon,
  BookmarkIcon,
  SwatchIcon,
  CircleStackIcon,
  DevicePhoneMobileIcon,
  FaceSmileIcon,
  FolderIcon,
  GlobeAltIcon,
  LockClosedIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IosNavbar from "../../components/ios/IosNavbar";
import IosListGroupItem from "../../components/ios/IosListGroupItem";
import IosListGroup from "../../components/ios/IosListGroup";
import AccountDetails from "../../business/dto/account-details";
import IosListGroupInputItem from "../../components/ios/IosListGroupInputItem";
import useData from "../../contexts/data-auth";

const items = [
  /*{
    name: "Saved Messages",
    href: "#",
    iconColor: "bg-blue-500",
    icon: BookmarkIcon,
    extra: "",
  },
  {
    name: "Recent Calls",
    href: "#",
    iconColor: "bg-green-500",
    icon: PhoneIcon,
    extra: "",
  },
  {
    name: "Devices",
    href: "#",
    iconColor: "bg-orange-500",
    icon: DevicePhoneMobileIcon,
    extra: "5",
  },
  {
    name: "Chat Folders",
    href: "#",
    iconColor: "bg-sky-500",
    icon: FolderIcon,
    extra: "",
  },
  {
    name: "Notifications and Sounds",
    href: "#",
    iconColor: "bg-red-500",
    icon: BellIcon,
    extra: "",
  },
  {
    name: "Privacy and Security",
    href: "#",
    iconColor: "bg-gray-500",
    icon: LockClosedIcon,
    extra: "",
  },
  {
    name: "Data and Storage",
    href: "#",
    iconColor: "bg-green-500",
    icon: CircleStackIcon,
    extra: "",
  },*/
  {
    name: "Theme",
    icon: SwatchIcon,
    extra: "Dark",
  },
  {
    name: "Language",
    icon: GlobeAltIcon,
    extra: "English",
  },
  /*{
    name: "Sticker and Emoji",
    href: "#",
    iconColor: "bg-orange-500",
    icon: FaceSmileIcon,
    extra: "",
  },*/
];

export default function EditAccount() {
  // const [user, setUser] = useState<AccountDetails>(null);
  const { data } = useData();

  useEffect(() => {
    // loadMe().then((user: AccountDetails) => setUser(user));
  }, []);

  const handleInputChange = (e) => {
    // setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <IosNavbar
        title={"Edit Account"}
        small={true}
        leftAction={
          <Link
            to="/account"
            replace={true}
            className="flex items-center ml-[-24px]"
          >
            <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
            <span className="ml-[-4px] pl-[4px]">Account</span>
          </Link>
        }
      />

      {data.account && (
        <>
          <IosListGroup title="Name">
            <IosListGroupInputItem
              title="Username"
              name="username"
              placeholder="Username"
              value={data.account.username}
              onChange={handleInputChange}
            />
          </IosListGroup>
          <IosListGroup title="Identifiers">
            <IosListGroupItem
              title={"Username"}
              extra={data.account.username}
            />
            <IosListGroupItem title={"Email"} extra={data.account.email} />
          </IosListGroup>
          <IosListGroup
            title="Security"
            description="Your password must be at least 8 characters long, include a number, an uppercase letter and a lowercase letter."
          >
            <IosListGroupItem title={"Password"} extra="●●●●●●●●" />
          </IosListGroup>
        </>
      )}
    </>
  );
}
